@layer components {
    .show-more-section{
        .show-more-button{
            @apply cursor-pointer hidden;
            span:last-child{
                @apply hidden;
            }
        }
        .show-more-container{
            @apply transition-all duration-300 overflow-hidden;
            max-height: 200px;
        }
        &.opened{
            .show-more-button{
                span:first-child{
                    @apply hidden;
                }
                span:last-child{
                    @apply block;
                }
            }
            .show-more-container{
                max-height: 9999px !important;
            }
        }
    }
}
