@layer components {
    .google-reviews {
        &--bg {
            background-color: $google-reviews-bg;
        }

        &--unicolor {
            .google-reviews__logo {
                filter: contrast(0%) brightness(200%);
            }

            .google-reviews__count, .text-primary {
                color: $google-reviews-unicolor;
            }

            .icon-star-half-primary-to-separator {
                background: linear-gradient(90deg, $google-reviews-unicolor 0%, $google-reviews-unicolor 50%, $google-reviews-unicolor-disabled 50%, $google-reviews-unicolor-disabled 100%);
                -webkit-background-clip: text;
            }
        }

        &--inline .google-reviews {
            &__container {
                flex-direction: row !important;
                gap: 1.25rem;
            }

            &__logo, &__rating {
                margin: initial;
            }
        }

        &--header {
            height: 100%;
            min-width: $google-header-width;
            max-width: calc($google-header-width + 5rem);
            margin-left: auto;
            align-items: center;
        }
    }
}
