header{
    @each $color in $ar-colors {
        @if $color == background {
            .header-bg-#{$color} {
                @apply lg:bg-background;
                @screen lg{
                    ul{
                        @apply text-font-primary;
                    }
                }
                .burger-menu-container {
                    @apply lg:text-font-primary;
                }
                .site-logo-light {
                    @apply lg:hidden;
                }
                .site-logo {
                    @apply lg:block;
                }
                .ar-underline{
                    &:after{
                        @apply bg-primary;
                    }
                }
            }
        } @else {
            .header-bg-#{$color} {
                @apply lg:bg-background-#{$color};
            }
        }
    }
    .site-menu{
        @apply fixed top-0 left-0 h-full w-full bg-primary translate-x-full;
        &.opened{
            @apply translate-x-0;
        }
    }
    .menu-links{
        @apply overflow-auto;
        height: calc(100vh - 73px);
    }
}

body.no-cover{
    header{
        @each $color in $ar-colors {
            @if $color == background {
                .header-bg-#{$color} {
                    @apply bg-background;
                    .site-logo{
                        @apply block;
                    }
                    .site-logo-light{
                        @apply hidden;
                    }
                    .site-listen{
                        @apply text-font-primary;
                    }
                    .burger-menu-container{
                        @apply text-font-primary;
                    }
                }
            } @else {
                .header-bg-#{$color} {
                    @apply bg-background-#{$color};
                }
            }
        }
    }
}

@screen lg{
    header{
        @apply bg-white mb-[72px];
        box-shadow: 0 2px 4px 0 rgba(162, 168, 181, 0.34);
        display: grid;
        grid-template-columns: minmax($google-header-width,auto) minmax(max-content,var(--container-max-width)) minmax($google-header-width,auto);
        gap: max(.25rem, 2%);


        @media (min-width: 1024px) {
            --container-max-width: calc(1024px - 4rem);
        }
        @media (min-width: 1280px) {
            --container-max-width: 1160px;
        }
        @media (min-width: 1536px) {
            --container-max-width: 1256px;
        }

        .site-menu{
            @apply translate-x-0 absolute h-auto w-full py-5.5 top-[72px] opacity-0 [transition:opacity_.2s_0s,background-color_.2s_.2s,box-shadow_.2s_.2s];
            @each $color in $ar-colors {
                @if $color == background {
                    &.header-bg-#{$color} {
                        @apply bg-background;
                        .site-logo{
                            @apply block;
                        }
                        .site-logo-light{
                            @apply hidden;
                        }
                        .site-listen{
                            @apply text-font-primary;
                        }
                        .burger-menu-container{
                            @apply text-font-primary;
                        }
                    }
                } @else {
                    &.header-bg-#{$color} {
                        @apply bg-background-#{$color};
                    }
                }
            }
            ul{
                @apply [transition:color_.2s_.2s];
            }
            li{
                @apply ml-7.5;
                &:first-of-type{
                    @apply ml-0;
                }
            }
        }
        .menu-links{
            @apply h-auto overflow-visible whitespace-nowrap;
        }
        .sub-menu-container{
            box-shadow: 0 4px 8px 0 rgba(133, 142, 161, 0.3);
            li{
                @apply ml-0;
            }
        }
    }
}

