@font-face {
  font-family: 'Minion-Pro';
  src: local('Minion Pro'),
  local('Minion-Pro'),
  local('minion-pro'),
  url('/fonts/minion-pro/MinionPro-Regular.eot');
  src: url('/fonts/minion-pro/MinionPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/minion-pro/MinionPro-Regular.woff') format('woff'),
  url('/fonts/minion-pro/MinionPro-Regular.woff2') format('woff2'),
  url('/fonts/minion-pro/MinionPro-Regular.ttf') format('truetype'),
  url('/fonts/minion-pro/MinionPro-Regular.svg#MinionPro-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Minion-Pro-Bold';
  src: local('Minion Pro Bold'),
  local('Minion-Pro-Bold'),
  local('minion-pro-Bold'),
  url('/fonts/minion-pro/MinionPro-Bold.eot');
  src: url('/fonts/minion-pro/MinionPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/minion-pro/MinionPro-Bold.woff') format('woff'),
  url('/fonts/minion-pro/MinionPro-Bold.woff2') format('woff2'),
  url('/fonts/minion-pro/MinionPro-Bold.ttf') format('truetype'),
  url('/fonts/minion-pro/MinionPro-Bold.svg#MinionPro-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gill-Sans-Light';
  src: url('/fonts/gillsans/GillSans-Light.eot');
  src: local('Gill Sans Light'), local('GillSans-Light'),
  url('/fonts/gillsans/GillSans-Light.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gillsans/GillSans-Light.woff2') format('woff2'),
  url('/fonts/gillsans/GillSans-Light.woff') format('woff'),
  url('/fonts/gillsans/GillSans-Light.ttf') format('truetype'),
  url('/fonts/gillsans/GillSans-Light.svg#GillSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gill-Sans';
  src: url('/fonts/gillsans/GillSans.eot');
  src: local('Gill Sans'), local('GillSans'),
  url('/fonts/gillsans/GillSans.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gillsans/GillSans.woff2') format('woff2'),
  url('/fonts/gillsans/GillSans.woff') format('woff'),
  url('/fonts/gillsans/GillSans.ttf') format('truetype'),
  url('/fonts/gillsans/GillSans.svg#GillSans') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?6xu5k');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?6xu5k#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?6xu5k') format('woff2'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?6xu5k') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?6xu5k') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?6xu5k##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow; 
  }
}
.icon-arrow_back {
  &:before {
    content: $icon-arrow_back; 
  }
}
.icon-arrow-top {
  &:before {
    content: $icon-arrow-top; 
  }
}
.icon-back {
  &:before {
    content: $icon-back; 
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel; 
  }
}
.icon-checkbox {
  &:before {
    content: $icon-checkbox; 
  }
}
.icon-checkbox-checked {
  &:before {
    content: $icon-checkbox-checked; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-dot {
  &:before {
    content: $icon-dot; 
  }
}
.icon-large_dot {
  &:before {
    content: $icon-large_dot; 
  }
}
.icon-dropdown {
  &:before {
    content: $icon-dropdown; 
  }
}
.icon-dropup {
  &:before {
    content: $icon-dropup; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-infos {
  &:before {
    content: $icon-infos; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-localisation {
  &:before {
    content: $icon-localisation; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-next {
  &:before {
    content: $icon-next; 
  }
}
.icon-quotation {
  &:before {
    content: $icon-quotation; 
  }
}
.icon-radio {
  &:before {
    content: $icon-radio; 
  }
}
.icon-radio_checked {
  &:before {
    content: $icon-radio_checked; 
  }
}
.icon-star{
  &:before {
    content: $icon-star;
  }
  &-half{
    &-primary-to-separator{
      background: linear-gradient(90deg, theme('colors.primary') 0%, theme('colors.primary') 50%, theme('colors.separator') 50%, theme('colors.separator') 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

