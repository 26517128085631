@layer components {
    .modal{
        @apply fixed w-full h-full max-w-full z-30 top-0 left-0;
        .modal-header{
            height: $modal-title-height;
        }
        .modal-close{
            width: 50px;
            height: 50px;
        }
        .modal-container{
            @apply bg-white h-full;
        }
        .scrollable-content{
            @apply overflow-auto;
            max-height: calc(100vh - #{$modal-title-height} - 112px - 40px);
        }
    }
    .modal-overlay{
        @apply fixed w-full h-full z-10 top-0 left-0 hidden opacity-50 bg-black;
        &:after{
            content: "";
            @apply w-full h-full top-0 left-0 backdrop-blur-sm;
        }
    }
    .trigger-modal{
        @apply cursor-pointer;
    }

    @screen md{
        .modal{
            @apply h-auto left-1/2;
            width: $modal-width;
            .modal-container{
                @apply overflow-hidden;
            }
            .scrollable-content{
                max-height: calc(100vh - #{$modal-title-height} - 85px - 40px);
            }
        }
    }
    #contact-form-modal{
        .modal-header{
            margin-top: 112px;
        }
    }

    @screen md{
        #contact-form-modal{
            .modal-header{
                margin-top: 85px;
            }
        }
    }
    div[id^='tribute-infos'] {
        @apply h-auto px-6;
        top: 30% !important;
        .modal-close{
            @apply bg-white text-font-primary mr-6;
        }
    }
}
