@layer components {
	.btn{
		@apply #{$button-shape};
		&-primary{
			@apply bg-primary border-primary text-font-light;
			&:not(.btn-disable-hover){
				&:hover{
					@apply bg-primary-beta border-primary-beta;
				}
			}
		}
		&-secondary{
			@apply bg-secondary border-secondary text-font-light;
			&:not(.btn-disable-hover){
				&:hover{
					@apply bg-secondary-beta border-secondary-beta;
				}
			}
		}
		&-light{
			@apply bg-background border-background text-font-primary;
			&:not(.btn-disable-hover){
                &:hover{
                    @apply bg-background-beta border-background-beta;
                }
                &:active{
                    @apply bg-background-beta border-background-beta;
                }
			}
		}
		&-outline{
			&-primary{
				@apply border-primary text-primary;
				&:not(.btn-disable-hover){
					&:hover{
						@apply bg-primary border-primary text-font-light;
					}
				}
			}
			&-light{
				@apply border-background text-font-light;
				&:not(.btn-disable-hover){
					&:hover{
						@apply bg-background text-font-primary;
					}
				}
			}
		}
		&-icon{
			@apply pr-8;
			i{
				@apply text-2xl w-6 leading-6 text-center relative left-3;
			}
			&-left{
				@apply pr-5 pl-8;
				i{
					@apply -left-3;
				}
			}
			&-only{
				@apply px-4.5 py-4.5;
				i{
					@apply left-0;
				}
			}
		}
		&-small{
			@apply #{$small-button-shape};
			&.btn-icon{
				&-only{
					@apply px-3 py-3;
                    &.btn-icon-mobile-small{
                        @apply px-[5px] pt-0.5 pb-0;
                    }
				}
			}
		}
		&-extra-small{
			@apply #{$extra-small-button-shape};
			&.btn-icon{
				@apply pr-5;
				i{
					@apply text-2xl w-6 leading-6 text-center relative left-3;
				}
				&-left{
					@apply pr-3 pl-5;
					i{
						@apply -left-3;
					}
				}
				&-only{
					@apply px-4.5 py-4.5;
					i{
						@apply left-0;
					}
				}
			}
		}
		&-hidden-mobile{
			@apply hidden;
		}
        &-disabled{
            @apply opacity-70 pointer-events-none;
        }
	}

	@screen md{
		.btn{
			&-hidden-mobile{
				@apply inline-flex;
			}
		}
	}

    @screen lg{
        .btn-small{
            &.btn-icon-only{
                &.btn-icon-mobile-small{
                    @apply px-3 py-3;
                }
            }
        }
    }
}
