@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Minion-Pro";
  src: local("Minion Pro"), local("Minion-Pro"), local("minion-pro"), url("/fonts/minion-pro/MinionPro-Regular.eot");
  src: url("/fonts/minion-pro/MinionPro-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/minion-pro/MinionPro-Regular.woff") format("woff"), url("/fonts/minion-pro/MinionPro-Regular.woff2") format("woff2"), url("/fonts/minion-pro/MinionPro-Regular.ttf") format("truetype"), url("/fonts/minion-pro/MinionPro-Regular.svg#MinionPro-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Minion-Pro-Bold";
  src: local("Minion Pro Bold"), local("Minion-Pro-Bold"), local("minion-pro-Bold"), url("/fonts/minion-pro/MinionPro-Bold.eot");
  src: url("/fonts/minion-pro/MinionPro-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/minion-pro/MinionPro-Bold.woff") format("woff"), url("/fonts/minion-pro/MinionPro-Bold.woff2") format("woff2"), url("/fonts/minion-pro/MinionPro-Bold.ttf") format("truetype"), url("/fonts/minion-pro/MinionPro-Bold.svg#MinionPro-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gill-Sans-Light";
  src: url("/fonts/gillsans/GillSans-Light.eot");
  src: local("Gill Sans Light"), local("GillSans-Light"), url("/fonts/gillsans/GillSans-Light.eot?#iefix") format("embedded-opentype"), url("/fonts/gillsans/GillSans-Light.woff2") format("woff2"), url("/fonts/gillsans/GillSans-Light.woff") format("woff"), url("/fonts/gillsans/GillSans-Light.ttf") format("truetype"), url("/fonts/gillsans/GillSans-Light.svg#GillSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gill-Sans";
  src: url("/fonts/gillsans/GillSans.eot");
  src: local("Gill Sans"), local("GillSans"), url("/fonts/gillsans/GillSans.eot?#iefix") format("embedded-opentype"), url("/fonts/gillsans/GillSans.woff2") format("woff2"), url("/fonts/gillsans/GillSans.woff") format("woff"), url("/fonts/gillsans/GillSans.ttf") format("truetype"), url("/fonts/gillsans/GillSans.svg#GillSans") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "autre-rive-icons";
  src: url("/fonts/autre-rive-icons//autre-rive-icons.eot?6xu5k");
  src: url("/fonts/autre-rive-icons//autre-rive-icons.eot?6xu5k#iefix") format("embedded-opentype"), url("/fonts/autre-rive-icons//autre-rive-icons.woff2?6xu5k") format("woff2"), url("/fonts/autre-rive-icons//autre-rive-icons.ttf?6xu5k") format("truetype"), url("/fonts/autre-rive-icons//autre-rive-icons.woff?6xu5k") format("woff"), url("/fonts/autre-rive-icons//autre-rive-icons.svg?6xu5k#autre-rive-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "autre-rive-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e917";
}

.icon-add:before {
  content: "\e900";
}

.icon-arrow:before {
  content: "\e901";
}

.icon-arrow_back:before {
  content: "\e902";
}

.icon-arrow-top:before {
  content: "\e903";
}

.icon-back:before {
  content: "\e904";
}

.icon-cancel:before {
  content: "\e905";
}

.icon-checkbox:before {
  content: "\e906";
}

.icon-checkbox-checked:before {
  content: "\e907";
}

.icon-close:before {
  content: "\e908";
}

.icon-dot:before {
  content: "\e909";
}

.icon-large_dot:before {
  content: "\e90a";
}

.icon-dropdown:before {
  content: "\e90b";
}

.icon-dropup:before {
  content: "\e90c";
}

.icon-facebook:before {
  content: "\e90d";
}

.icon-infos:before {
  content: "\e90e";
}

.icon-linkedin:before {
  content: "\e90f";
}

.icon-localisation:before {
  content: "\e910";
}

.icon-menu:before {
  content: "\e911";
}

.icon-next:before {
  content: "\e912";
}

.icon-quotation:before {
  content: "\e913";
}

.icon-radio:before {
  content: "\e914";
}

.icon-radio_checked:before {
  content: "\e915";
}

.icon-star:before {
  content: "\e916";
}
.icon-star-half-primary-to-separator {
  background: linear-gradient(90deg, theme("colors.primary") 0%, theme("colors.primary") 50%, theme("colors.separator") 50%, theme("colors.separator") 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@layer base {
  html {
    @apply overflow-x-hidden;
  }
  html.menu-opened {
    @apply overflow-hidden;
  }
  html.menu-opened #floating-scroll-to-top {
    @apply hidden;
  }

  #floating-scroll-to-top {
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  }

  body {
    @apply overflow-x-hidden text-font-primary text-body-2;
  }

  h1, h2, h3, h4, h5, h6, p {
    @apply m-0;
  }

  ol, ul {
    @apply list-none p-0 m-0;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
  }

  hr {
    @apply border-primary;
  }

  html, * {
    @apply antialiased;
  }

  table {
    @apply border-collapse;
  }

  a, a:active, a:hover, a:focus {
    @apply no-underline;
  }

  label {
    @apply mb-0;
  }

  ul, li {
    @apply m-0 p-0;
  }

  #site-container {
    @apply transition-all duration-300 relative;
  }

  .landing-overlay {
    height: 380px;
    top: -73px;
  }
  .landing-overlay.agency {
    top: 0;
  }
  .landing-overlay:before {
    content: "";
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0) 99%);
    @apply absolute top-0 left-0 w-full;
    height: 130px;
  }

  .black-overlay:before {
    content: "";
    @apply absolute top-0 left-0 w-full h-full bg-black bg-opacity-40;
  }

  [class^=block-], [class*=" block-"], [class^=inner-section-], [class*=" inner-section-"] {
    padding: 56px 0;
  }

  @screen md {
    .landing-overlay {
      height: 480px;
    }
  }
  @screen lg {
    [class^=block-], [class*=" block-"], [class^=inner-section-], [class*=" inner-section-"] {
      padding: 120px 0;
    }

    .landing-container, .landing-overlay {
      @apply top-0 flex items-center;
      height: 700px;
    }
    .landing-container:before, .landing-overlay:before {
      @apply content-none;
    }
    .landing-container.small-landing, .landing-overlay.small-landing {
      height: 480px;
    }
    .landing-container.medium-landing, .landing-overlay.medium-landing {
      height: 600px;
    }
  }
}
@layer components {
  .text {
    @apply font-primary-regular;
  }
  .text-title {
    @apply font-primary-regular text-7.5xl leading-none;
  }
  .text-title-2 {
    @apply font-primary-regular text-6xl leading-70px;
  }
  .text-title-3 {
    @apply font-primary-regular text-5.5xl leading-60px;
  }
  .text-title-3-lh56 {
    @apply font-primary-regular text-5.5xl leading-56px;
  }
  .text-title-4 {
    @apply font-primary-regular text-4.5xl leading-48px;
  }
  .text-title-4-lh44 {
    @apply font-primary-regular text-4.5xl leading-44px;
  }
  .text-title-5 {
    @apply font-primary-regular text-3.5xl leading-10;
  }
  .text-title-6 {
    @apply font-primary-regular text-3xl leading-10;
  }
  .text-title-6-lh36 {
    @apply font-primary-regular text-3xl leading-9;
  }
  .text-subtitle {
    @apply font-primary-bold text-3.5xl leading-10;
  }
  .text-subtitle-2 {
    @apply font-primary-regular text-2.5xl leading-9;
  }
  .text-subtitle-3 {
    @apply font-primary-regular text-2xl leading-7.5;
  }
  .text-subtitle-4 {
    @apply font-primary-bold text-xl leading-7.5;
  }
  .text-body {
    @apply font-primary-regular text-3xl leading-10;
  }
  .text-body-2 {
    @apply font-primary-regular text-xl leading-7.5;
  }
  .text-body-2-lh26 {
    @apply font-primary-regular text-xl leading-6.5;
  }
  .text-button {
    @apply font-primary-bold text-3xl leading-10;
  }
  .text-button-2 {
    @apply font-primary-bold text-xl leading-7;
  }
  .text-button-3 {
    @apply font-primary-bold text-lg leading-6;
  }
  .text-button-4 {
    @apply font-primary-bold text-base leading-5;
  }
  .text-caption {
    @apply font-secondary-regular text-xl leading-6;
  }
  .text-caption-2 {
    @apply font-secondary-light text-lg leading-6;
  }
  .text-caption-3 {
    @apply font-secondary-regular text-base leading-5;
  }
  .text-caption-4 {
    @apply font-secondary-regular text-sm;
  }
  .text-caption-5 {
    @apply font-secondary-light text-sm;
  }
  .text-overline {
    @apply font-primary-regular text-base leading-5;
  }
}
@layer components {
  .btn {
    @apply text-button-2 inline-flex items-center py-3.75 px-5 cursor-pointer appearance-none outline-none transition-colors duration-200 border border-transparent;
  }
  .btn-primary {
    @apply bg-primary border-primary text-font-light;
  }
  .btn-primary:not(.btn-disable-hover):hover {
    @apply bg-primary-beta border-primary-beta;
  }
  .btn-secondary {
    @apply bg-secondary border-secondary text-font-light;
  }
  .btn-secondary:not(.btn-disable-hover):hover {
    @apply bg-secondary-beta border-secondary-beta;
  }
  .btn-light {
    @apply bg-background border-background text-font-primary;
  }
  .btn-light:not(.btn-disable-hover):hover {
    @apply bg-background-beta border-background-beta;
  }
  .btn-light:not(.btn-disable-hover):active {
    @apply bg-background-beta border-background-beta;
  }
  .btn-outline-primary {
    @apply border-primary text-primary;
  }
  .btn-outline-primary:not(.btn-disable-hover):hover {
    @apply bg-primary border-primary text-font-light;
  }
  .btn-outline-light {
    @apply border-background text-font-light;
  }
  .btn-outline-light:not(.btn-disable-hover):hover {
    @apply bg-background text-font-primary;
  }
  .btn-icon {
    @apply pr-8;
  }
  .btn-icon i {
    @apply text-2xl w-6 leading-6 text-center relative left-3;
  }
  .btn-icon-left {
    @apply pr-5 pl-8;
  }
  .btn-icon-left i {
    @apply -left-3;
  }
  .btn-icon-only {
    @apply px-4.5 py-4.5;
  }
  .btn-icon-only i {
    @apply left-0;
  }
  .btn-small {
    @apply py-2.5;
  }
  .btn-small.btn-icon-only {
    @apply px-3 py-3;
  }
  .btn-small.btn-icon-only.btn-icon-mobile-small {
    @apply px-[5px] pt-0.5 pb-0;
  }
  .btn-extra-small {
    @apply text-button-3 py-2.25 px-3;
  }
  .btn-extra-small.btn-icon {
    @apply pr-5;
  }
  .btn-extra-small.btn-icon i {
    @apply text-2xl w-6 leading-6 text-center relative left-3;
  }
  .btn-extra-small.btn-icon-left {
    @apply pr-3 pl-5;
  }
  .btn-extra-small.btn-icon-left i {
    @apply -left-3;
  }
  .btn-extra-small.btn-icon-only {
    @apply px-4.5 py-4.5;
  }
  .btn-extra-small.btn-icon-only i {
    @apply left-0;
  }
  .btn-hidden-mobile {
    @apply hidden;
  }
  .btn-disabled {
    @apply opacity-70 pointer-events-none;
  }

  @screen md {
    .btn-hidden-mobile {
      @apply inline-flex;
    }
  }
  @screen lg {
    .btn-small.btn-icon-only.btn-icon-mobile-small {
      @apply px-3 py-3;
    }
  }
}
@layer components {
  .chips {
    @apply rounded-full border text-body-2 cursor-pointer px-3 py-0.5 inline-flex items-center appearance-none outline-none transition-colors duration-200;
  }
  .chips-primary {
    @apply bg-primary border-primary text-font-light;
  }
  .chips-primary:hover {
    @apply border-primary-beta;
  }
  .chips-primary.active, .chips-primary:focus {
    @apply bg-primary-beta border-primary-beta;
  }
  .chips-secondary {
    @apply bg-secondary border-secondary text-font-light;
  }
  .chips-secondary:hover {
    @apply border-secondary-beta;
  }
  .chips-secondary.active, .chips-secondary:focus {
    @apply bg-secondary-beta border-secondary-beta;
  }
  .chips-white {
    @apply bg-white border-white text-black;
  }
  .chips-white:hover {
    @apply border-black;
  }
  .chips-white.active, .chips-white:focus {
    @apply bg-black text-white border-black;
  }
  .chips-black {
    @apply bg-black border-black text-white;
  }
  .chips-black:hover {
    @apply border-white;
  }
  .chips-black.active, .chips-black:focus {
    @apply bg-white text-black border-white;
  }
  .chips-icon > *:not(i) {
    @apply pl-2;
  }
  .chips-icon i {
    @apply text-xl w-5 leading-5 text-center relative -left-0.5;
  }
  .chips-icon-right > *:not(i), .chips-icon-both > *:not(i) {
    @apply pr-2;
  }
  .chips-icon-right i:last-child, .chips-icon-both i:last-child {
    @apply left-0.5;
  }
  .chips-icon-only {
    @apply px-2.5;
  }
  .chips-icon-only i {
    @apply left-0;
  }
}
@layer components {
  select.custom-input {
    @apply appearance-none outline-none;
  }
  select.custom-input:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }

  textarea.custom-input {
    @apply appearance-none outline-none;
  }
  textarea.custom-input:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }

  textarea.custom-input {
    @apply leading-6;
  }

  .btn-font-regular {
    @apply font-primary-regular;
  }

  .custom-input {
    @apply py-1 leading-50px text-body-2 text-font-primary px-4 text-body-2 bg-background min-w-0 max-w-full border border-background relative;
  }
  .custom-input[type=text] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=text]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input [type=text] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=text]::placeholder {
    @apply text-font-tertiary;
  }
  .custom-input [type=text]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input[type=number] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=number]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input [type=number] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=number]::placeholder {
    @apply text-font-tertiary;
  }
  .custom-input [type=number]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input[type=email] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=email]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input [type=email] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=email]::placeholder {
    @apply text-font-tertiary;
  }
  .custom-input [type=email]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input[type=password] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=password]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input [type=password] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=password]::placeholder {
    @apply text-font-tertiary;
  }
  .custom-input [type=password]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input[type=tel] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=tel]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input [type=tel] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=tel]::placeholder {
    @apply text-font-tertiary;
  }
  .custom-input [type=tel]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input select {
    @apply appearance-none outline-none;
  }
  .custom-input select::placeholder {
    @apply text-font-tertiary;
  }
  .custom-input textarea {
    @apply appearance-none outline-none;
  }
  .custom-input textarea::placeholder {
    @apply text-font-tertiary;
  }
  .custom-input::placeholder {
    @apply text-font-tertiary;
  }
  .custom-input textarea {
    @apply leading-6;
  }
  .custom-input select {
    @apply bg-background w-full;
  }
  .custom-input select:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input select {
    @apply cursor-pointer w-full;
  }
  .custom-input select:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input.select-container, .custom-input .select-container {
    @apply lg:h-full py-1 px-0;
  }
  .custom-input.select-container .select2-container, .custom-input .select-container .select2-container {
    @apply px-4;
    width: 100% !important;
  }
  .custom-input.select-container .select2-selection, .custom-input .select-container .select2-selection {
    @apply w-full border-none bg-transparent;
  }
  .custom-input.select-container .select2-selection__rendered, .custom-input .select-container .select2-selection__rendered {
    padding-left: 0 !important;
    color: #46536e !important;
  }
  .custom-input.select-container .select2-selection__arrow, .custom-input .select-container .select2-selection__arrow {
    @apply hidden;
  }
  .custom-input.select-container .select2-results__option--selectable, .custom-input .select-container .select2-results__option--selectable {
    @apply px-4 py-2.5;
  }
  .custom-input.select-container i, .custom-input .select-container i {
    @apply absolute right-1 leading-9 w-9 text-tertiary text-3xl text-center pointer-events-none pt-1.5;
  }
  .custom-input.focus, .custom-input:focus {
    @apply border-tertiary;
  }
  .custom-input.error {
    @apply border-error;
  }
  .custom-input.disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input-color-primary {
    @apply text-primary border-primary;
  }
  .custom-input-color-primary [type=text] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=text]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary [type=number] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=number]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary [type=email] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=email]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary [type=password] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=password]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary [type=tel] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=tel]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary select {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary select::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary textarea {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary textarea::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary.focus, .custom-input-color-primary:focus {
    @apply border-primary-beta;
  }
  .custom-input-color-primary::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary.select-container i, .custom-input-color-primary .select-container i {
    @apply text-primary;
  }
  .custom-input-color-primary .custom-input-radio input, .custom-input-color-primary .custom-input-checkbox input {
    @apply border-primary;
  }
  .custom-input-color-primary .custom-input-radio input:checked:before, .custom-input-color-primary .custom-input-checkbox input:checked:before {
    content: "";
    @apply bg-primary;
  }
  .custom-input-color-secondary {
    @apply text-secondary border-secondary;
  }
  .custom-input-color-secondary [type=text] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=text]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary [type=number] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=number]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary [type=email] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=email]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary [type=password] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=password]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary [type=tel] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=tel]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary select {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary select::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary textarea {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary textarea::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary.focus, .custom-input-color-secondary:focus {
    @apply border-secondary-beta;
  }
  .custom-input-color-secondary::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary.select-container i, .custom-input-color-secondary .select-container i {
    @apply text-secondary;
  }
  .custom-input-color-secondary .custom-input-radio input, .custom-input-color-secondary .custom-input-checkbox input {
    @apply border-secondary;
  }
  .custom-input-color-secondary .custom-input-radio input:checked:before, .custom-input-color-secondary .custom-input-checkbox input:checked:before {
    content: "";
    @apply bg-secondary;
  }
  .custom-input-bg-primary {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=text] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=text]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary [type=number] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=number]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary [type=email] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=email]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary [type=password] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=password]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary [type=tel] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=tel]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary select {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary select::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary textarea {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary textarea::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary.focus, .custom-input-bg-primary:focus {
    @apply border-primary-beta;
  }
  .custom-input-bg-primary::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary.select-container i, .custom-input-bg-primary .select-container i {
    @apply text-font-light;
  }
  .custom-input-bg-secondary {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=text] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=text]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary [type=number] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=number]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary [type=email] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=email]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary [type=password] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=password]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary [type=tel] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=tel]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary select {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary select::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary textarea {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary textarea::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary.focus, .custom-input-bg-secondary:focus {
    @apply border-secondary-beta;
  }
  .custom-input-bg-secondary::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary.select-container i, .custom-input-bg-secondary .select-container i {
    @apply text-font-light;
  }
  .custom-input-small {
    @apply leading-10;
  }
  .custom-input-small.select-container i, .custom-input-small .select-container i {
    @apply leading-7 w-7 text-lg;
  }
  .custom-input-checkbox-group .invalid-feedback, .custom-input-radio-group .invalid-feedback {
    @apply basis-full;
  }

  .custom-input-radio, .custom-input-checkbox {
    @apply flex items-center;
  }
  .custom-input-radio input, .custom-input-checkbox input {
    @apply appearance-none w-4 h-4 border-2 border-black rounded-full relative mr-2 cursor-pointer;
  }
  .custom-input-radio input:checked:before, .custom-input-checkbox input:checked:before {
    content: "";
    @apply absolute w-2 h-2 bg-black left-0.5 top-0.5 rounded-full;
  }
  .custom-input-radio input:disabled, .custom-input-checkbox input:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default pointer-events-none;
  }
  .custom-input-radio label, .custom-input-checkbox label {
    @apply cursor-pointer;
  }
  .custom-input-checkbox input {
    @apply rounded-md;
  }
  .custom-input-checkbox input:checked:before {
    @apply rounded-md;
  }

  .btn-radio, .btn-checkbox {
    @apply text-7xl;
  }

  .select-outer {
    @apply bg-transparent border-primary text-primary;
  }
  .select-outer select {
    @apply bg-white;
  }
  .select-outer-opacity {
    @apply border-opacity-50;
  }

  .iti {
    color: #db897b;
  }
}
.select2-dropdown {
  width: calc(100% + 2px) !important;
  left: -1px !important;
  border-radius: unset !important;
  border-color: #46536e !important;
  z-index: 30 !important;
  margin-top: 10px !important;
}
.select2-dropdown--above {
  margin-top: -12px !important;
}
.select2-dropdown.select-outer-opacity {
  border-color: rgba(70, 83, 110, 0.5) !important;
}
.select2-dropdown.select-agencies {
  border-color: #F3F1EF !important;
}

.select2-results__options {
  max-height: max-content !important;
  padding: 0.625rem 0 !important;
}

.select2-results__option {
  padding: 0.375rem 1rem !important;
}

.select2-results__option--selectable {
  background-color: white;
}

.select2-results__option--selected {
  display: none;
}

.select2-results__option--selected, .select2-results__option--highlighted {
  background-color: white !important;
  color: #46536e !important;
}

@layer components {
  .modal {
    @apply fixed w-full h-full max-w-full z-30 top-0 left-0;
  }
  .modal .modal-header {
    height: 50px;
  }
  .modal .modal-close {
    width: 50px;
    height: 50px;
  }
  .modal .modal-container {
    @apply bg-white h-full;
  }
  .modal .scrollable-content {
    @apply overflow-auto;
    max-height: calc(100vh - 50px - 112px - 40px);
  }

  .modal-overlay {
    @apply fixed w-full h-full z-10 top-0 left-0 hidden opacity-50 bg-black;
  }
  .modal-overlay:after {
    content: "";
    @apply w-full h-full top-0 left-0 backdrop-blur-sm;
  }

  .trigger-modal {
    @apply cursor-pointer;
  }

  @screen md {
    .modal {
      @apply h-auto left-1/2;
      width: 560px;
    }
    .modal .modal-container {
      @apply overflow-hidden;
    }
    .modal .scrollable-content {
      max-height: calc(100vh - 50px - 85px - 40px);
    }
  }
  #contact-form-modal .modal-header {
    margin-top: 112px;
  }

  @screen md {
    #contact-form-modal .modal-header {
      margin-top: 85px;
    }
  }
  div[id^=tribute-infos] {
    @apply h-auto px-6;
    top: 30% !important;
  }
  div[id^=tribute-infos] .modal-close {
    @apply bg-white text-font-primary mr-6;
  }
}
@layer components {
  .slide-content-section .slide-content-container {
    @apply hidden;
  }
  .slide-content-section .slide-content-container.slide-content-open {
    @apply block;
  }
  .slide-content-section .slide-content-button {
    @apply cursor-pointer;
  }

  @screen md {
    .slide-content-section.slide-content-only-mobile .slide-content-container {
      @apply block;
    }
    .slide-content-section.slide-content-only-mobile .slide-content-button {
      @apply cursor-default;
    }
    .slide-content-section.slide-content-only-mobile .slide-content-button i {
      @apply hidden;
    }
  }
}
@layer components {
  .nav-animate {
    @apply overflow-hidden overflow-x-scroll w-full whitespace-nowrap;
  }
  .nav-animate::-webkit-scrollbar-thumb {
    @apply bg-transparent rounded-none;
  }
  .nav-animate::-webkit-scrollbar {
    @apply w-0 h-0;
  }
  .nav-animate::-ms-scrollbar-thumb {
    @apply bg-transparent rounded-none;
  }
  .nav-animate::-ms-scrollbar {
    @apply w-0 h-0;
  }
  .nav-animate::-moz-scrollbar-thumb {
    @apply bg-transparent rounded-none;
  }
  .nav-animate::-moz-scrollbar {
    @apply w-0 h-0;
  }
  .nav-animate.nav-tabs {
    @apply border-none;
  }
  .nav-animate.nav-full-width {
    display: table;
  }
  .nav-animate.nav-full-width li {
    display: table-cell;
  }
  .nav-animate .nav-item {
    @apply relative;
  }
  .nav-animate a, .nav-animate span {
    @apply inline-block font-secondary-regular;
  }
  .nav-animate .active-bar {
    @apply absolute left-0 z-10 -bottom-0.5 h-1 w-full bg-black;
  }
  .nav-animate .active-bar.right {
    @apply right-0;
  }
  .nav-animate .active-bar-vertical {
    @apply bottom-0 top-3 w-1 h-10;
  }
  .nav-animate .nav-item {
    @apply cursor-pointer;
  }

  #webkit-nav ul {
    @apply flex items-center relative;
    height: 50px;
  }
  #webkit-nav ul .active-bar {
    @apply h-1;
    bottom: -13px;
  }
  #webkit-nav.fixed {
    @apply py-0 left-0 w-screen bg-gray-100 z-10 border-b border-gray-300;
    top: 73px;
  }

  @screen md {
    #webkit-nav.fixed {
      margin-top: 0;
    }
  }
  .ceremony-types-nav .nav-animate, .monument-materials-nav .nav-animate {
    @apply flex items-center relative;
    height: 64px;
    box-shadow: inset 0px -2px 0px 0px #c5c9d1;
  }
  .ceremony-types-nav .nav-animate .active-bar, .monument-materials-nav .nav-animate .active-bar {
    @apply h-[2px] bg-secondary;
    bottom: -17px;
  }
  .ceremony-types-nav .nav-animate li, .monument-materials-nav .nav-animate li {
    @apply text-tertiary flex-grow text-center mr-0 transition-colors duration-200;
  }
  .ceremony-types-nav .nav-animate li:hover, .monument-materials-nav .nav-animate li:hover {
    @apply text-secondary-beta;
  }
  .ceremony-types-nav .nav-animate li.active, .monument-materials-nav .nav-animate li.active {
    @apply text-secondary;
  }
  .ceremony-types-nav .nav-animate li span, .monument-materials-nav .nav-animate li span {
    @apply text-button-2 mx-4;
  }
  .ceremony-types-nav.white-background .nav-animate, .monument-materials-nav.white-background .nav-animate {
    @apply bg-white;
  }
  .ceremony-types-nav.classic-background .nav-animate, .monument-materials-nav.classic-background .nav-animate {
    @apply bg-background;
  }

  @screen lg {
    .ceremony-types-nav .nav-animate li span, .monument-materials-nav .nav-animate li span {
      @apply mx-0;
    }
    .ceremony-types-nav.white-background .nav-animate, .ceremony-types-nav.classic-background .nav-animate, .monument-materials-nav.white-background .nav-animate, .monument-materials-nav.classic-background .nav-animate {
      @apply bg-transparent;
    }
  }
}
@layer components {
  .show-more-section .show-more-button {
    @apply cursor-pointer hidden;
  }
  .show-more-section .show-more-button span:last-child {
    @apply hidden;
  }
  .show-more-section .show-more-container {
    @apply transition-all duration-300 overflow-hidden;
    max-height: 200px;
  }
  .show-more-section.opened .show-more-button span:first-child {
    @apply hidden;
  }
  .show-more-section.opened .show-more-button span:last-child {
    @apply block;
  }
  .show-more-section.opened .show-more-container {
    max-height: 9999px !important;
  }
}
@layer components {
  .owl-section .owl-carousel {
    @apply overflow-x-hidden;
  }
  .owl-section .owl-carousel .owl-stage,
.owl-section .owl-carousel .owl-item {
    @apply flex;
  }
  .owl-section .owl-carousel .owl-stage img,
.owl-section .owl-carousel .owl-item img {
    @apply w-auto;
  }
  .owl-section .owl-carousel .owl-item {
    @apply flex-1;
  }
  .owl-section .custom-owl-nav span {
    @apply flex justify-center items-center cursor-pointer border border-gray-300 rounded-full text-black text-xl transition-colors duration-200;
    width: 38px;
    height: 38px;
  }
  .owl-section .custom-owl-nav span.disabled {
    @apply text-gray-300 border-gray-300;
  }
  .owl-section .custom-owl-nav span:hover:not(.disabled) {
    @apply text-black;
  }
  .owl-section .custom-owl-nav-squared span {
    @apply rounded-none text-font-light;
    width: 60px;
    height: 60px;
  }
  .owl-section .custom-owl-nav-squared span.disabled {
    @apply text-gray-300 border-gray-300;
  }
  .owl-section .custom-owl-nav-squared span:hover:not(.disabled) {
    @apply text-font-light;
  }
  .owl-section .owl-dots {
    @apply flex items-center gap-5 justify-center mt-5;
  }
  .owl-section .owl-dots button {
    @apply bg-gray-300 flex rounded-full h-5 w-5 transition-colors duration-200;
  }
  .owl-section .owl-dots button:focus {
    @apply outline-none;
  }
  .owl-section .owl-dots button.active {
    @apply bg-black;
  }
  .owl-section-compact-dots .owl-dots {
    @apply flex items-center gap-3 justify-center mx-1 absolute mt-5;
  }
  .owl-section-compact-dots .owl-dots button {
    @apply bg-background opacity-40 flex rounded-full h-2 w-2 transition-colors duration-200;
  }
  .owl-section-compact-dots .owl-dots button:focus {
    @apply outline-none;
  }
  .owl-section-compact-dots .owl-dots button.active {
    @apply bg-background opacity-100;
  }
  .owl-section-compact-dots .owl-carousel {
    @apply overflow-x-visible;
  }
  .owl-section-compact-dots .owl-carousel .owl-stage,
.owl-section-compact-dots .owl-carousel .owl-item {
    @apply flex;
  }
  .owl-section-compact-dots .owl-carousel .owl-stage img,
.owl-section-compact-dots .owl-carousel .owl-item img {
    @apply w-auto;
  }
  .owl-section-compact-dots .owl-carousel .owl-item {
    @apply flex-1;
  }
  .owl-section-compact-dots-light .owl-dots button {
    @apply bg-primary;
  }
  .owl-section-compact-dots-light .owl-dots button.active {
    @apply bg-primary;
  }
  @screen lg {
    .owl-section-compact-dots-right-corner .owl-dots {
      @apply absolute bottom-2.5 right-2.5;
    }
  }
  .owl-section-shifted .owl-container {
    @apply pr-0;
  }
}
@layer components {
  .container {
    @apply mx-auto px-4 md:px-6 lg:px-8 xl:px-[60px] 2xl:px-[140px];
  }

  .grid {
    @apply gap-y-10 gap-x-10;
  }
}
@layer components {
  .wysiwyg-content {
    /* case if background color is orange : make bullet points blue */
  }
  .wysiwyg-content p {
    @apply text-body-2-lh26 lg:text-body-2;
  }
  .wysiwyg-content ul + p {
    @apply mt-6;
  }
  .wysiwyg-content strong {
    @apply font-primary-bold;
  }
  .wysiwyg-content em {
    @apply italic;
  }
  .wysiwyg-content u {
    @apply underline;
  }
  .wysiwyg-content a {
    @apply underline;
  }
  .wysiwyg-content a:hover {
    @apply no-underline;
  }
  .wysiwyg-content h3 {
    @apply text-title-6-lh36 lg:text-title-3 break-words mb-8 lg:mb-10;
  }
  .wysiwyg-content p + h3, .wysiwyg-content ul + h3 {
    @apply mt-16;
  }
  .wysiwyg-content h4 {
    @apply text-subtitle-3 lg:text-title-5 mb-4 lg:mb-2;
  }
  .wysiwyg-content p + h4, .wysiwyg-content ul + h4 {
    @apply mt-6;
  }
  .wysiwyg-content h5 {
    @apply text-subtitle-2 lg:text-title-5;
  }
  .wysiwyg-content h5 + p {
    @apply mt-4 lg:mt-2;
  }
  .wysiwyg-content p + h5, .wysiwyg-content ul + h5 {
    @apply mt-6;
  }
  .wysiwyg-content .ql-size-small {
    @apply text-subtitle-4;
  }
  .wysiwyg-content .ql-size-large {
    @apply text-body-2-lh26 lg:text-body;
  }
  .wysiwyg-content .ql-size-huge {
    @apply text-subtitle-3 lg:text-title-5;
  }
  .wysiwyg-content ul {
    @apply text-body-2-lh26 lg:text-body-2 -indent-7 pl-7;
  }
  .wysiwyg-content ul li:before {
    @apply not-italic font-normal normal-case relative -bottom-0.5 mr-2 h-6;
    font-family: "autre-rive-icons" !important;
    speak: never;
    font-variant: normal;
    color: #db897b;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e909";
  }
  .wysiwyg-content ul li + li {
    @apply mt-4;
  }
  .wysiwyg-content p + ul {
    @apply mt-4 lg:mt-6;
  }
  .wysiwyg-content.secondary-bullet-points ul li:before {
    color: #46536e;
  }
}
@layer components {
  .google-reviews--bg {
    background-color: #db897b;
  }
  .google-reviews--unicolor .google-reviews__logo {
    filter: contrast(0%) brightness(200%);
  }
  .google-reviews--unicolor .google-reviews__count, .google-reviews--unicolor .text-primary {
    color: #f3f1ef;
  }
  .google-reviews--unicolor .icon-star-half-primary-to-separator {
    background: linear-gradient(90deg, #f3f1ef 0%, #f3f1ef 50%, #46536e 50%, #46536e 100%);
    -webkit-background-clip: text;
  }
  .google-reviews--inline .google-reviews__container {
    flex-direction: row !important;
    gap: 1.25rem;
  }
  .google-reviews--inline .google-reviews__logo, .google-reviews--inline .google-reviews__rating {
    margin: initial;
  }
  .google-reviews--header {
    height: 100%;
    min-width: 125px;
    max-width: calc(125px + 5rem);
    margin-left: auto;
    align-items: center;
  }
}
@layer utilities {
  .errors-container, .success-container {
    @apply hidden;
  }

  .invalid-feedback, .success-feedback {
    @apply text-base inline-block w-full text-body;
  }

  .success-feedback {
    @apply text-success;
  }

  .invalid-feedback {
    @apply text-error;
  }

  .loading {
    @apply relative overflow-hidden;
  }
  .loading:before {
    content: "";
    @apply absolute w-full h-full left-0 top-0 bg-white bg-opacity-50 z-0;
  }
  .loading:after {
    content: "";
    @apply absolute left-1/2 top-1/2 border-8 border-white rounded-full border-t-primary border-b-primary z-0 -mt-6 -ml-6 w-12 h-12 animate-spin;
  }
  .loading-no-opacity:before {
    @apply bg-white;
  }

  .v-align-solo {
    @apply relative top-1/2 translate-y-1/2;
  }

  .blurred {
    @apply blur-sm;
  }

  .header-fixed, body.no-cover .header-fixed, .header-agency {
    @apply fixed top-0 left-0 w-full z-30 border-b bg-white;
  }
  .header-fixed .ar-underline:after, body.no-cover .header-fixed .ar-underline:after, .header-agency .ar-underline:after {
    @apply bg-primary;
  }
  .header-fixed .site-listen, body.no-cover .header-fixed .site-listen, .header-agency .site-listen {
    @apply text-font-primary;
  }
  .header-fixed .burger-menu-container, body.no-cover .header-fixed .burger-menu-container, .header-agency .burger-menu-container {
    @apply text-font-primary;
  }
  .header-fixed + *, body.no-cover .header-fixed + *, .header-agency + * {
    margin-top: 73px;
  }
  .header-fixed .site-logo, body.no-cover .header-fixed .site-logo, .header-agency .site-logo {
    min-width: 20px;
    @apply block;
  }
  .header-fixed .site-logo-light, body.no-cover .header-fixed .site-logo-light, .header-agency .site-logo-light {
    @apply hidden;
  }

  .quote-message:before {
    content: "“";
    @apply text-title;
  }

  ul.ul-inline > li {
    @apply inline-block;
  }
  ul.ul-normal > li {
    @apply block;
  }

  .ar-underline {
    @apply relative;
  }
  .ar-underline:after {
    content: "";
    @apply absolute bottom-0 left-0 w-full h-0.5 bg-primary;
    transform: scaleX(0);
    transform-origin: bottom left;
    transition: transform 0.3s;
  }
  .ar-underline:hover:after {
    transform: scaleX(1);
  }
  .ar-underline-reverse:after {
    transform: scaleX(1);
  }
  .ar-underline-reverse:hover:after {
    transform: scaleX(0);
  }
  .ar-underline-fixed:after {
    transform: scaleX(1);
  }
  .ar-underline-fixed:hover:after {
    transform: scaleX(1);
  }
  .ar-underline-primary:after {
    @apply bg-primary;
  }
  .ar-underline-secondary:after {
    @apply bg-secondary;
  }
  .ar-underline-background:after {
    @apply bg-background;
  }
  .ar-underline-white:after {
    @apply bg-white;
  }
  .ar-underline-bg-current:after {
    @apply bg-current;
  }

  @screen lg {
    .header-fixed-show .site-menu {
      @apply opacity-100;
    }

    header:not(.header-fixed-show) .site-menu {
      @apply pointer-events-none;
    }

    .header-fixed + *, body.no-cover .header-fixed + * {
      margin-top: 145px;
    }
    .header-fixed ul, body.no-cover .header-fixed ul {
      @apply text-font-primary;
    }
    .header-fixed .site-menu, body.no-cover .header-fixed .site-menu {
      box-shadow: 0 2px 4px 0 rgba(162, 168, 181, 0.34);
    }
    .header-fixed .site-menu.header-bg-primary, body.no-cover .header-fixed .site-menu.header-bg-primary {
      @apply bg-white;
    }
    .header-fixed .site-menu.header-bg-secondary, body.no-cover .header-fixed .site-menu.header-bg-secondary {
      @apply bg-white;
    }
    .header-fixed .site-menu.header-bg-background, body.no-cover .header-fixed .site-menu.header-bg-background {
      @apply bg-white;
    }
  }
  #categories-filter {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  #categories-filter::-webkit-scrollbar {
    display: none;
  }
}
header .header-bg-primary {
  @apply lg:bg-background-primary;
}
header .header-bg-secondary {
  @apply lg:bg-background-secondary;
}
header .header-bg-background {
  @apply lg:bg-background;
}
@screen lg {
  header .header-bg-background ul {
    @apply text-font-primary;
  }
}
header .header-bg-background .burger-menu-container {
  @apply lg:text-font-primary;
}
header .header-bg-background .site-logo-light {
  @apply lg:hidden;
}
header .header-bg-background .site-logo {
  @apply lg:block;
}
header .header-bg-background .ar-underline:after {
  @apply bg-primary;
}
header .site-menu {
  @apply fixed top-0 left-0 h-full w-full bg-primary translate-x-full;
}
header .site-menu.opened {
  @apply translate-x-0;
}
header .menu-links {
  @apply overflow-auto;
  height: calc(100vh - 73px);
}

body.no-cover header .header-bg-primary {
  @apply bg-background-primary;
}
body.no-cover header .header-bg-secondary {
  @apply bg-background-secondary;
}
body.no-cover header .header-bg-background {
  @apply bg-background;
}
body.no-cover header .header-bg-background .site-logo {
  @apply block;
}
body.no-cover header .header-bg-background .site-logo-light {
  @apply hidden;
}
body.no-cover header .header-bg-background .site-listen {
  @apply text-font-primary;
}
body.no-cover header .header-bg-background .burger-menu-container {
  @apply text-font-primary;
}

@screen lg {
  header {
    @apply bg-white mb-[72px];
    box-shadow: 0 2px 4px 0 rgba(162, 168, 181, 0.34);
    display: grid;
    grid-template-columns: minmax(125px, auto) minmax(max-content, var(--container-max-width)) minmax(125px, auto);
    gap: max(0.25rem, 2%);
  }
  @media (min-width: 1024px) {
    header {
      --container-max-width: calc(1024px - 4rem);
    }
  }
  @media (min-width: 1280px) {
    header {
      --container-max-width: 1160px;
    }
  }
  @media (min-width: 1536px) {
    header {
      --container-max-width: 1256px;
    }
  }
  header .site-menu {
    @apply translate-x-0 absolute h-auto w-full py-5.5 top-[72px] opacity-0 [transition:opacity_.2s_0s,background-color_.2s_.2s,box-shadow_.2s_.2s];
  }
  header .site-menu.header-bg-primary {
    @apply bg-background-primary;
  }
  header .site-menu.header-bg-secondary {
    @apply bg-background-secondary;
  }
  header .site-menu.header-bg-background {
    @apply bg-background;
  }
  header .site-menu.header-bg-background .site-logo {
    @apply block;
  }
  header .site-menu.header-bg-background .site-logo-light {
    @apply hidden;
  }
  header .site-menu.header-bg-background .site-listen {
    @apply text-font-primary;
  }
  header .site-menu.header-bg-background .burger-menu-container {
    @apply text-font-primary;
  }
  header .site-menu ul {
    @apply [transition:color_.2s_.2s];
  }
  header .site-menu li {
    @apply ml-7.5;
  }
  header .site-menu li:first-of-type {
    @apply ml-0;
  }
  header .menu-links {
    @apply h-auto overflow-visible whitespace-nowrap;
  }
  header .sub-menu-container {
    box-shadow: 0 4px 8px 0 rgba(133, 142, 161, 0.3);
  }
  header .sub-menu-container li {
    @apply ml-0;
  }
}
.employee-photo-responsive-width {
  width: 164px !important;
}
@screen lg {
  .employee-photo-responsive-width {
    width: 360px !important;
  }
}

.schedule-content p {
  @apply text-body-2-lh26 lg:text-body;
}
.schedule-content .ql-size-small {
  @apply text-overline;
}
.schedule-content .ql-size-large {
  @apply text-subtitle-3 lg:text-title-5;
}
.schedule-content .ql-size-huge {
  @apply text-subtitle-2 lg:text-title-4;
}
.schedule-content u {
  @apply underline;
}

.block-image {
  @apply h-[400px] xl:h-[545px] bg-cover bg-center bg-no-repeat;
}

.image-inner {
  @apply h-[400px] xl:h-[545px];
}

.testimonies-block .owl-dots {
  @apply -bottom-12 left-0;
}

.block-image-carousel div[id^=block-carousel-block_carousel_image-] {
  @apply h-[500px] xl:h-[720px] bg-cover bg-center bg-no-repeat;
}
.block-image-carousel .carousel-overlay {
  @apply h-[500px] xl:h-[720px] container grid grid-cols-2 xl:grid-cols-12 py-16;
}
.block-image-carousel .custom-owl-dots {
  @apply m-0 relative h-min;
}
.block-image-carousel .custom-owl-nav.custom-owl-nav-squared span.disabled {
  opacity: 50%;
}
@media only screen and (max-device-width: 768px) {
  .block-image-carousel .custom-owl-nav.custom-owl-nav-squared {
    display: none;
  }
}

.block-navigation nav ul li a {
  @apply text-button-3 lg:text-button inline-block w-full flex justify-between lg:justify-start;
}
.block-navigation nav ul li a i {
  @apply ml-3 text-2xl relative bottom-1.5 lg:-bottom-0.5;
}
.block-navigation nav ul li + li {
  @apply mt-7 lg:mt-6;
}
.block-navigation nav ul p {
  @apply text-overline lg:text-body-2;
}

.block-tribute.bg-primary {
  @apply text-white;
}
.block-tribute.bg-primary .display-infos {
  @apply bg-primary-beta text-white;
}
.block-tribute.bg-secondary {
  @apply text-white;
}
.block-tribute.bg-secondary .display-infos {
  @apply bg-secondary-beta text-white;
}
.block-tribute.bg-background .display-infos {
  @apply bg-white;
}

.iti {
  position: relative;
  display: inline-block;
}
.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.iti__hide {
  display: none;
}
.iti__v-hide {
  visibility: hidden;
}
.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}
.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}
.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}
.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}
.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}
@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: 20px;
}
.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}
.iti__country {
  padding: 5px 10px;
  outline: none;
}
.iti__dial-code {
  color: #999;
}
.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}
.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}
.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}
.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}
.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}
.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}
.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}
.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}
.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}
.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}
.iti__flag.iti__be {
  width: 18px;
}
.iti__flag.iti__ch {
  width: 15px;
}
.iti__flag.iti__mc {
  width: 19px;
}
.iti__flag.iti__ne {
  width: 18px;
}
.iti__flag.iti__np {
  width: 13px;
}
.iti__flag.iti__va {
  width: 15px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}
.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}
.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}
.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}
.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}
.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}
.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}
.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}
.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}
.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}
.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}
.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}
.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}
.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}
.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}
.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}
.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}
.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}
.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}
.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}
.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}
.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}
.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}
.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}
.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}
.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}
.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}
.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}
.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}
.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}
.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}
.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}
.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}
.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}
.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}
.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}
.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}
.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}
.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}
.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}
.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}
.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}
.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}
.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}
.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}
.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}
.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}
.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}
.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}
.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}
.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}
.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}
.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}
.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}
.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}
.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}
.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}
.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}
.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}
.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}
.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}
.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}
.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}
.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}
.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}
.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}
.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}
.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}
.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}
.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}
.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}
.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}
.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}
.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}
.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}
.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}
.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}
.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}
.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}
.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}
.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}
.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}
.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}
.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}
.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}
.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}
.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}
.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}
.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}
.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}
.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}
.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}
.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}
.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}
.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}
.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}
.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}
.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}
.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}
.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}
.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}
.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}
.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}
.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}
.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}
.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}
.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}
.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}
.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}
.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}
.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}
.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}
.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}
.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}
.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}
.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}
.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}
.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}
.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}
.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}
.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}
.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}
.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}
.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}
.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}
.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}
.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}
.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}
.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}
.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}
.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}
.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}
.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}
.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}
.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}
.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}
.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}
.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}
.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}
.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}
.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}
.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}
.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}
.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}
.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}
.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}
.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}
.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}
.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}
.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}
.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}
.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}
.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}
.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}
.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}
.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}
.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}
.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}
.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}
.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}
.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}
.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}
.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}
.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}
.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}
.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}
.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}
.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}
.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}
.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}
.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}
.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}
.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}
.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}
.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}
.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}
.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}
.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}
.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}
.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}
.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}
.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}
.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}
.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}
.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}
.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}
.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}
.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}
.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}
.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}
.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}
.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}
.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}
.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}
.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}
.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}
.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}
.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}
.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}
.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}
.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}
.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}
.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}
.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}
.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}
.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}
.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}
.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}
.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}
.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}
.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}
.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}
.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}
.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}
.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}
.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}
.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}
.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}
.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}
.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}
.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}
.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}
.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}
.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}
.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}
.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}
.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}
.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}
.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}
.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}
.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}
.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}
.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}
.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}
.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}
.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}
.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}
.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}
.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}
.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}
.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}
.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}
.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}
.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}
.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}
.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}
.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}
.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}
.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}
.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}
.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}
.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}
.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}
.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}
.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}
.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}
.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}
.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}
.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("/images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("/images/flags@2x.png");
  }
}

.iti__flag.iti__np {
  background-color: transparent;
}