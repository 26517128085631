@layer components {
	@each $input-tag in $input-tags {
		#{$input-tag}.custom-input{
			@apply appearance-none outline-none;
			&:disabled{
				@apply #{$disabled-classes};
			}
		}
	}
	textarea{
		&.custom-input{
			@apply leading-6;
		}
	}
    .btn{
        &-font-regular{
            @apply font-primary-regular;
        }
    }
	.custom-input{
		@each $input-type in $input-types {
			&#{$input-type}{
				@apply appearance-none outline-none;
				&:disabled{
					@apply #{$disabled-classes};
				}
			}
			#{$input-type}{
				@apply appearance-none outline-none;
				&::placeholder {
					@apply #{$placeholder-color};
				}
				&:disabled{
					@apply #{$disabled-classes};
				}
			}
		}
		@each $input-tag in $input-tags {
			#{$input-tag}{
				@apply appearance-none outline-none;
				&::placeholder {
					@apply #{$placeholder-color};
				}
			}
		}
		@apply #{$input-shape};
		&::placeholder {
			@apply #{$placeholder-color};
		}
		textarea{
			@apply leading-6;
		}
		select{
			@apply bg-background w-full;
			&:disabled{
				@apply #{$disabled-classes};
			}
		}
		select{	
			@apply cursor-pointer w-full;
			&:disabled{
				@apply #{$disabled-classes};
			}
		}
		&.select-container, .select-container {
			@apply lg:h-full py-1 px-0;
			.select2 {
				&-container {
					@apply px-4;
					width: 100% !important;
				}
				&-selection {
					@apply w-full border-none bg-transparent;
					&__rendered {
						padding-left: 0 !important;
						color: #46536e !important;
					}
					&__arrow {
						@apply hidden;
					}
				}
				&-results__option--selectable {
					@apply px-4 py-2.5;
				}
			}
			i{
				@apply absolute right-1 leading-9 w-9 text-tertiary text-3xl text-center pointer-events-none pt-1.5;
			}
		}
		&.focus, &:focus{
			@apply border-tertiary;
		}
		&.error{
			@apply border-error;
		}
		&.disabled{
			@apply #{$disabled-classes};
		}

		&-color-primary{
			@apply text-primary border-primary;
			@each $input-type in $input-types {
				#{$input-type}{
					@apply border-primary text-primary-beta;
					&::placeholder {
						@apply text-primary;
					}
				}
			}
			@each $input-tag in $input-tags {
				#{$input-tag}{
					@apply border-primary text-primary-beta;
					&::placeholder {
						@apply text-primary;
					}
				}
			}
			&.focus, &:focus{
				@apply border-primary-beta;
			}
			&::placeholder {
				@apply text-primary;
			}
			&.select-container, .select-container{
				i{
					@apply text-primary;
				}
			}
			.custom-input{
				&-radio, &-checkbox{
					input{
						@apply border-primary;
						&:checked{
							&:before{
								content: "";
								@apply bg-primary;
							}
						}
					}
				}
			}
		}
		&-color-secondary{
			@apply text-secondary border-secondary;
			@each $input-type in $input-types {
				#{$input-type}{
					@apply border-secondary text-secondary-beta;
					&::placeholder {
						@apply text-secondary;
					}
				}
			}
			@each $input-tag in $input-tags {
				#{$input-tag}{
					@apply border-secondary text-secondary-beta;
					&::placeholder {
						@apply text-secondary;
					}
				}
			}
			&.focus, &:focus{
				@apply border-secondary-beta;
			}
			&::placeholder {
				@apply text-secondary;
			}
			&.select-container, .select-container{
				i{
					@apply text-secondary;
				}
			}
			.custom-input{
				&-radio, &-checkbox{
					input{
						@apply border-secondary;
						&:checked{
							&:before{
								content: "";
								@apply bg-secondary;
							}
						}
					}
				}
			}
		}

		&-bg-primary{
			@apply bg-primary border-primary text-font-light;
			@each $input-type in $input-types {
				#{$input-type}{
					@apply bg-primary border-primary text-font-light;
					&::placeholder {
						@apply text-font-light;
					}
				}
			}
			@each $input-tag in $input-tags {
				#{$input-tag}{
					@apply bg-primary border-primary text-font-light;
					&::placeholder {
						@apply text-font-light;
					}
				}
			}
			&.focus, &:focus{
				@apply border-primary-beta;
			}
			&::placeholder {
				@apply text-font-light;
			}
			&.select-container, .select-container{
				i{
					@apply text-font-light;
				}
			}
		}
		&-bg-secondary{
			@apply bg-secondary border-secondary text-font-light;
			@each $input-type in $input-types {
				#{$input-type}{
					@apply bg-secondary border-secondary text-font-light;
					&::placeholder {
						@apply text-font-light;
					}

				}
			}
			@each $input-tag in $input-tags {
				#{$input-tag}{
					@apply bg-secondary border-secondary text-font-light;
					&::placeholder {
						@apply text-font-light;
					}
				}
			}
			&.focus, &:focus{
				@apply border-secondary-beta;
			}
			&::placeholder {
				@apply text-font-light;
			}
			&.select-container, .select-container{
				i{
					@apply text-font-light;
				}
			}
		}

		&-small{
			@apply #{$small-input-shape};
			&.select-container, .select-container{
				i{
					@apply leading-7 w-7 text-lg;
				}
			}
		}
		&-checkbox-group, &-radio-group{
			.invalid-feedback{
				@apply basis-full;
			}
		}
	}
	.custom-input{
		&-radio, &-checkbox{
			@apply flex items-center;
			input{
				@apply appearance-none w-4 h-4 border-2 border-black rounded-full relative mr-2 cursor-pointer;
				&:checked{
					&:before{
						content: "";
						@apply absolute w-2 h-2 bg-black left-0.5 top-0.5 rounded-full;
					}
				}
				&:disabled{
					@apply #{$disabled-classes};
				}
			}
			label{
				@apply cursor-pointer;
			}
		}
		&-checkbox{
			input{
				@apply rounded-md;
				&:checked{
					&:before{
						@apply rounded-md;
					}
				}
			}
		}
	}
    .btn{
        &-radio, &-checkbox{
            @apply text-7xl;
        }
    }
    .select-outer {
        @apply bg-transparent border-primary text-primary;
        select {
            @apply bg-white;
        }
        &-opacity {
            @apply border-opacity-50;
        }
    }
    .iti{
        color: $primary;
    }
}

// Select2 classes to override default css
.select2-dropdown {
	width: calc(100% + 2px) !important;
	left: -1px !important;
	border-radius: unset !important;
	border-color: rgb(70, 83, 110) !important;
	z-index: 30 !important;
	margin-top: 10px !important;

	&--above {
		margin-top: -12px !important;
	}

	&.select-outer-opacity {
		border-color: rgb(70, 83, 110, .5) !important;
	}
	&.select-agencies {
		border-color: #F3F1EF !important;
	}
}

.select2-results__options {
	max-height: max-content !important;
	padding: 0.625rem 0 !important;
}

.select2-results__option {
	padding: 0.375rem 1rem !important;
}

.select2-results__option--selectable {
	background-color: white;
}

.select2-results__option--selected {
    display: none;
}

.select2-results__option--selected, .select2-results__option--highlighted {
	background-color: white !important;
	color: #46536e !important;
}
