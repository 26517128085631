// Container
$gutters-size: 'px-4 md:px-6 lg:px-8 xl:px-[60px] 2xl:px-[140px]';

// Grids
$grid-gaps: 'gap-y-10 gap-x-10';

// Header
$header-height: 73px;
$tablet-header-height: 145px;
$desktop-header-height: 145px;
$google-header-width: 125px;

// Modals
$modal-title-height: 50px;
$modal-width: 560px;

// Buttons
$button-shape: 'text-button-2 inline-flex items-center py-3.75 px-5 cursor-pointer appearance-none outline-none transition-colors duration-200 border border-transparent';
$small-button-shape: 'py-2.5';
$extra-small-button-shape: 'text-button-3 py-2.25 px-3';

// Chips
$chips-shape: 'rounded-full border text-body-2 cursor-pointer px-3 py-0.5 inline-flex items-center appearance-none outline-none transition-colors duration-200';

// Inputs
$input-shape: 'py-1 leading-50px text-body-2 text-font-primary px-4 text-body-2 bg-background min-w-0 max-w-full border border-background relative';
$small-input-shape: 'leading-10';
$placeholder-color: 'text-font-tertiary';
$disabled-classes: 'bg-gray-200 border-gray-200 cursor-default pointer-events-none';
$input-types: ('[type="text"]', '[type="number"]', '[type="email"]', '[type="password"]', '[type="tel"]');
$input-tags: ('select', 'textarea');

// Colors
$ar-colors: ('primary', 'secondary', 'background');
$ar-all-colors: ('primary', 'secondary', 'background', 'white');
$primary: #db897b;
$primary-beta: #d17060;
$secondary: #46536e;
$secondary-beta: #344364;
$background: #db897b;
$background-beta: #46536e;
$background-gamma: #f3f1ef;
$font-primary: #db897b;
$font-secondary: #46536e;
$font-dark: #000000;
$font-medium: #a2a8b5;
$font-light: #f3f1ef;
$error: #a25b5b;
$success: #62705e;
$custom-light-beta: #dad5cf;

// Google Reviews Colors
$google-reviews-bg: $font-primary;
$google-reviews-unicolor: $font-light;
$google-reviews-unicolor-disabled: $font-secondary;

// Fonts
$font-name: 'Minion-Pro';
$secondary-font-name: 'Gill-Sans';

$font-primary-regular: '#{$font-name}';
$font-primary-bold: '#{$font-name}-Bold';

$font-secondary-regular: '#{$secondary-font-name}';
$font-secondary-light: '#{$secondary-font-name}-Light';

// Font icon
$icomoon-font-family: "autre-rive-icons" !default;
$icomoon-font-path: "/fonts/autre-rive-icons/" !default;

$icon-phone: "\e917";
$icon-add: "\e900";
$icon-arrow: "\e901";
$icon-arrow_back: "\e902";
$icon-arrow-top: "\e903";
$icon-back: "\e904";
$icon-cancel: "\e905";
$icon-checkbox: "\e906";
$icon-checkbox-checked: "\e907";
$icon-close: "\e908";
$icon-dot: "\e909";
$icon-large_dot: "\e90a";
$icon-dropdown: "\e90b";
$icon-dropup: "\e90c";
$icon-facebook: "\e90d";
$icon-infos: "\e90e";
$icon-linkedin: "\e90f";
$icon-localisation: "\e910";
$icon-menu: "\e911";
$icon-next: "\e912";
$icon-quotation: "\e913";
$icon-radio: "\e914";
$icon-radio_checked: "\e915";
$icon-star: "\e916";

// intl-tel-input
$flagsImagePath: '/images/';
