.block-image-carousel {
    div[id^="block-carousel-block_carousel_image-"] {
        @apply h-[500px] xl:h-[720px] bg-cover bg-center bg-no-repeat;
    }

    .carousel-overlay {
        @apply h-[500px] xl:h-[720px] container grid grid-cols-2 xl:grid-cols-12 py-16;
    }

    .custom-owl-dots {
        @apply m-0 relative h-min;
    }

    .custom-owl-nav {
        &.custom-owl-nav-squared {
            span {
                &.disabled {
                    opacity: 50%;
                }
            }
            @media only screen and (max-device-width: 768px) {
                display: none;
            }
        }
    }
}
