@layer utilities {
	.errors-container, .success-container{
		@apply hidden;
	}
	.invalid-feedback, .success-feedback{
		@apply text-base inline-block w-full text-body;
	}
	.success-feedback{
		@apply text-success;
	}
	.invalid-feedback{
		@apply text-error;
	}
	.loading{
		@apply relative overflow-hidden;
		&:before{
			content: "";
			@apply absolute w-full h-full left-0 top-0 bg-white bg-opacity-50 z-0;
		}
		&:after{
			content: "";
			@apply absolute left-1/2 top-1/2 border-8 border-white rounded-full border-t-primary border-b-primary z-0 -mt-6 -ml-6 w-12 h-12 animate-spin;
		}
		&-no-opacity{
			&:before{
				@apply bg-white;
			}
		}
	}
	.v-align-solo{
		@apply relative top-1/2 translate-y-1/2;
	}
	.blurred{
		@apply blur-sm;
	}
	.header-fixed, body.no-cover .header-fixed, .header-agency{
		@apply fixed top-0 left-0 w-full z-30 border-b bg-white;
		.ar-underline{
			&:after{
				@apply bg-primary;
			}
		}
		.site-listen{
			@apply text-font-primary;
		}
		.burger-menu-container{
			@apply text-font-primary;
		}
		& + *{
			margin-top: $header-height;
		}
		.site-logo{
			min-width: 20px;
			@apply block;

			&-light{
				@apply hidden;
			}
		}
	}
	.quote-message{
		&:before {
			content: "“";
			@apply text-title;
		}
	}
	ul{
		&.ul-inline{
			& > li{
				@apply inline-block;
			}
		}
		&.ul-normal{
			& > li{
				@apply block;
			}
		}
	}

	.ar-underline{
		@apply relative;
		&:after {
			content: '';
			@apply absolute bottom-0 left-0 w-full h-0.5 bg-primary;
			transform: scaleX(0);
			transform-origin: bottom left;
			transition: transform 0.3s;
		}

		&:hover {
			&:after {
				transform: scaleX(1);
			}
		}
		&-reverse{
			&:after{
				transform: scaleX(1);
			}
			&:hover{
				&:after{
					transform: scaleX(0);
				}
			}
		}
		&-fixed{
			&:after{
				transform: scaleX(1);
			}
			&:hover{
				&:after{
					transform: scaleX(1);
				}
			}
		}
		@each $color in $ar-all-colors {
			&-#{$color}{
				&:after{
					@apply bg-#{$color};
				}
			}
		}
        &-bg-current{
            &:after{
                @apply bg-current;
            }
        }
	}
	@screen lg{
		.header-fixed-show{
			.site-menu{
				@apply opacity-100;
			}
		}
		header{
			&:not(.header-fixed-show){
				.site-menu{
					@apply pointer-events-none;
				}
			}
		}
		.header-fixed, body.no-cover .header-fixed{
			& + *{
				margin-top: $desktop-header-height;
			}
			ul{
				@apply text-font-primary;
			}
			.site-menu{
				box-shadow: 0 2px 4px 0 rgba(162, 168, 181, 0.34);
				@each $color in $ar-colors {
					&.header-bg-#{$color}{
						@apply bg-white;
					}
				}
			}
			
		}
	}
	#categories-filter{
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}
