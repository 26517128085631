 .employee-photo-responsive-width {
    width: 164px !important;
    @screen lg {
        width: 360px !important;
    }
}
.schedule-content {
    p {
        @apply text-body-2-lh26 lg:text-body;
    }
    .ql-size-small {
        @apply text-overline;
    }

    .ql-size-large {
        @apply text-subtitle-3 lg:text-title-5;
    }

    .ql-size-huge {
        @apply text-subtitle-2 lg:text-title-4;
    }

    u {
        @apply underline;
    }
}

