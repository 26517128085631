@layer components {
    .nav-animate{
        @apply overflow-hidden overflow-x-scroll w-full whitespace-nowrap;
        &::-webkit-scrollbar-thumb {
            @apply bg-transparent rounded-none;
        }
        &::-webkit-scrollbar {
            @apply w-0 h-0;
        }
        &::-ms-scrollbar-thumb {
            @apply bg-transparent rounded-none;
        }
        &::-ms-scrollbar {
            @apply w-0 h-0;
        }
        &::-moz-scrollbar-thumb {
            @apply bg-transparent rounded-none;
        }
        &::-moz-scrollbar {
            @apply w-0 h-0;
        }
        &.nav-tabs{
            @apply border-none;
        }
        &.nav-full-width{
            display: table;
            li{
                display: table-cell;
            }
        }
        .nav-item{
            @apply relative;
        }
        a, span{
            @apply inline-block font-secondary-regular;
        }
        .active-bar{
            @apply absolute left-0 z-10 -bottom-0.5 h-1 w-full bg-black;
            &.right{
                @apply right-0;
            }
            &-vertical{
                @apply bottom-0 top-3 w-1 h-10;
            }
        }
        .nav-item{
            @apply cursor-pointer;
        }
    }

    #webkit-nav{
        ul{
            @apply flex items-center relative;
            height: 50px;
            .active-bar{
                @apply h-1;
                bottom: -13px;
            }
        }
        &.fixed{
            @apply py-0 left-0 w-screen bg-gray-100 z-10 border-b border-gray-300;
            top: $header-height;
        }
    }
    @screen md{
        #webkit-nav{
            &.fixed{
                margin-top: 0;
            }
        }
    }
    
    .ceremony-types-nav, .monument-materials-nav{
        .nav-animate{
            @apply flex items-center relative;
            height: 64px;
            box-shadow: inset 0px -2px 0px 0px #c5c9d1;
            .active-bar{
                @apply h-[2px] bg-secondary;
                bottom: -17px;
            }
            
            li{
                @apply text-tertiary flex-grow text-center mr-0 transition-colors duration-200;
                &:hover{
                    @apply text-secondary-beta;
                }&.active{
                    @apply text-secondary;
                }
                span{
                    @apply text-button-2 mx-4;
                }
            }
        }
        &.white-background{
            .nav-animate{
                @apply bg-white;
            }
        }
        &.classic-background{
            .nav-animate{
                @apply bg-background;
            }
        }
    }
    @screen lg{
        .ceremony-types-nav, .monument-materials-nav{
            .nav-animate{
                li{
                    span{
                        @apply mx-0;
                    }
                }
            }
            &.white-background,&.classic-background{
                .nav-animate{
                    @apply bg-transparent;
                }
            }
        }
    }
}