@layer components {
    .wysiwyg-content {
        p {
            @apply text-body-2-lh26 lg:text-body-2;
        }

        ul + p {
            @apply mt-6;
        }

        strong {
            @apply font-primary-bold;
        }

        em {
            @apply italic;
        }

        u {
            @apply underline;
        }

        a {
            @apply underline;
            &:hover {
                @apply no-underline;
            }
        }

        h3 {
            @apply text-title-6-lh36 lg:text-title-3 break-words mb-8 lg:mb-10;
        }

        p + h3, ul + h3 {
            @apply mt-16;
        }

        h4 {
            @apply text-subtitle-3 lg:text-title-5 mb-4 lg:mb-2;
        }

        p + h4, ul + h4 {
            @apply mt-6;
        }

        h5 {
            @apply text-subtitle-2 lg:text-title-5;
        }

        h5 + p {
            @apply mt-4 lg:mt-2;
        }

        p + h5, ul + h5 {
            @apply mt-6;
        }

        .ql-size-small {
            @apply text-subtitle-4;
        }

        .ql-size-large {
            @apply text-body-2-lh26 lg:text-body;
        }

        .ql-size-huge {
            @apply text-subtitle-3 lg:text-title-5;
        }

        ul {
            @apply text-body-2-lh26 lg:text-body-2 -indent-7 pl-7;
            li {
                &:before {
                    @apply not-italic font-normal normal-case relative -bottom-0.5 mr-2 h-6;
                    font-family: '#{$icomoon-font-family}' !important;
                    speak: never;
                    font-variant: normal;
                    color: $primary;

                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    content: $icon-dot;
                }
            }

            li + li {
                @apply mt-4;
            }
        }

        p + ul {
            @apply mt-4 lg:mt-6;
        }
        /* case if background color is orange : make bullet points blue */
        &.secondary-bullet-points {
            ul {
                li {
                    &:before {
                        color: $secondary;
                    }
                }
            }
        }
    }
}
