@layer components {
    .owl-section {
        .owl-carousel {
            @apply overflow-x-hidden;
            .owl-stage,
            .owl-item {
                @apply flex;
                img {
                    @apply w-auto;
                }
            }
            .owl-item {
                @apply flex-1;
            }
        }
        .custom-owl-nav {
            span {
                @apply flex justify-center items-center cursor-pointer border border-gray-300 rounded-full text-black text-xl transition-colors duration-200;
                width: 38px;
                height: 38px;
                &.disabled {
                    @apply text-gray-300 border-gray-300;
                }
                &:hover {
                    &:not(.disabled) {
                        @apply text-black;
                    }
                }
            }

            &-squared {
                span {
                    @apply rounded-none text-font-light;
                    width: 60px;
                    height: 60px;
                    &.disabled {
                        @apply text-gray-300 border-gray-300;
                    }
                    &:hover {
                        &:not(.disabled) {
                            @apply text-font-light;
                        }
                    }
                }
            }
        }

        .owl-dots {
            @apply flex items-center gap-5 justify-center mt-5;
            button {
                @apply bg-gray-300 flex rounded-full h-5 w-5 transition-colors duration-200;
                &:focus {
                    @apply outline-none;
                }
                &.active {
                    @apply bg-black;
                }
            }
        }
        &-compact-dots {
            .owl-dots {
                @apply flex items-center gap-3 justify-center mx-1 absolute mt-5;
                button {
                    @apply bg-background opacity-40 flex rounded-full h-2 w-2 transition-colors duration-200;
                    &:focus {
                        @apply outline-none;
                    }
                    &.active {
                        @apply bg-background opacity-100;
                    }
                }
            }

            .owl-carousel {
                @apply overflow-x-visible;
                .owl-stage,
                .owl-item {
                    @apply flex;
                    img {
                        @apply w-auto;
                    }
                }
                .owl-item {
                    @apply flex-1;
                }
            }

            &-light {
                .owl-dots {
                    button {
                        @apply bg-primary;
                        &.active {
                            @apply bg-primary;
                        }
                    }
                }
            }

            &-right-corner {
                @screen lg {
                    .owl-dots {
                        @apply absolute bottom-2.5 right-2.5;
                    }
                }
            }
        }
        &-shifted {
            .owl-container {
                @apply pr-0;
            }
        }
    }
}
